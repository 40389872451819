import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import PatientList from 'components/PatientList'
import Patient from 'model/Patient'
import Notes from 'pages/Notes'
import React from 'react'
import { useSelector } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Route, RouteComponentProps } from 'react-router-dom'
import { AppState } from 'store/types'

interface Props {
  id: string
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
)

const Patients: React.FC<RouteComponentProps<Props>> = ({ match, history }) => {
  const patients = useSelector<AppState, readonly Patient[]>(
    (state) => state.firestore.ordered.users,
  )
  const {
    params: { id },
  } = match
  const classes = useStyles()

  const setSelectedPatientId = (id: string): void => {
    history.replace(`/patients/${id}`)
  }

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item xs={12} md={3}>
        {patients && (
          <PatientList
            patients={patients}
            selectedPatientId={id}
            setSelectedPatientId={setSelectedPatientId}
          />
        )}
      </Grid>
      <Grid item xs={12} md={9}>
        <Route path="/patients/:id" component={Notes} />
      </Grid>
    </Grid>
  )
}

export default firestoreConnect(['users'])(Patients as any) as any
