import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { FormikActions } from 'formik'
import Note from 'model/Note'
import React from 'react'
import AddNoteDialog, { Values } from './components/AddNoteDialog'
import t from './locales/pl';

interface Props {
  addNote: (note: Readonly<Pick<Note, 'title' | 'content'>>) => void
}

const AddNote: React.FC<Props> = ({ addNote }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleClickOpen = (): void => {
    setIsOpen(true)
  }

  const handleClose = (): void => {
    setIsOpen(false)
  }

  const handleSubmit = (
    values: Values,
    actions: FormikActions<Values>,
  ): void => {
    addNote(values)
    actions.setSubmitting(false)
    setIsOpen(false)
  }

  return (
    <Grid container>
      <Grid item xs>
        <Box my={1}>
          <Typography variant="h5" color="primary">
            {t.header}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box my={1} textAlign="right">
          <Button color="primary" onClick={handleClickOpen}>
            {t.addNoteButtonLabel}
          </Button>
          <AddNoteDialog
            isOpen={isOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default AddNote
