import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useDispatch } from 'react-redux'
import NoteModel from 'model/Note'
import Note from 'components/Notes/components/Note'
import { removeNoteActionCreator } from 'store/actionCreators/noteActionCreators'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
  }),
)

interface Props {
  notes: readonly NoteModel[]
}

const Notes: React.FC<Props> = ({ notes }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const deleteNote = React.useCallback((id: string) => {
    dispatch(removeNoteActionCreator({ id }))
  }, [dispatch])

  return (
    <div className={classes.root}>
      {notes.map((note) => (
        <Note
          key={note.id}
          note={note}
          deleteNote={() => deleteNote(note.id)}
        />
      ))}
    </div>
  )
}

export default Notes
