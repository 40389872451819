import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Field, Form, Formik, FormikActions } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import t from './locales/pl';

export interface Values {
  title: string
  content: string
}

interface Props {
  isOpen: boolean
  handleClose: () => void
  handleSubmit: (values: Values, actions: FormikActions<Values>) => void
}

const AddNoteDialog: React.FC<Props> = ({
  isOpen,
  handleClose,
  handleSubmit,
}) => (
  <Dialog
    open={isOpen}
    onClose={handleClose}
    aria-labelledby="form-dialog-title"
  >
    <Formik
      initialValues={{ title: '', content: '' }}
      onSubmit={handleSubmit}
      render={({ isSubmitting }) => (
        <Form>
          <DialogTitle id="form-dialog-title">{t.addNote}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t.dialogContentText}</DialogContentText>
            <Field
              component={TextField}
              name="title"
              label={t.dialogTitleLabel}
              margin="normal"
              fullWidth
              autoFocus
            />
            <Field
              component={TextField}
              name="content"
              label={t.dialogContentLabel}
              margin="normal"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={isSubmitting} onClick={handleClose}>
              {t.cancelButtonLabel}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              onClick={handleClose}
            >
              {t.submitButtonLabel}
            </Button>
          </DialogActions>
        </Form>
      )}
    />
  </Dialog>
)

export default AddNoteDialog
