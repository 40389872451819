import { ActionCreator } from 'redux'
import { ThunkAction } from 'redux-thunk'
import {
  ActionTypes,
  SignInActions,
  SignInData,
  SignOutActions,
  SignUpActions,
  SignUpData,
} from 'store/actions/authActions'
import { ThunkExtraArgument } from 'store/types'

type SignInThunkAction = ThunkAction<Promise<SignInActions>, any, ThunkExtraArgument, SignInActions>

export const signInActionCreator: ActionCreator<SignInThunkAction> = (payload: Readonly<SignInData>) => {
  const { email, password } = payload
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase()

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
      return dispatch({ type: ActionTypes.SIGN_IN_SUCCESS, payload })
    } catch (error) {
      return dispatch({ type: ActionTypes.SIGN_IN_FAIL, error })
    }
  }
}


type SignUpThunkAction = ThunkAction<Promise<SignUpActions>, any, ThunkExtraArgument, SignUpActions>

export const signUpActionCreator: ActionCreator<SignUpThunkAction> = (payload: Readonly<SignUpData>) => {
  const { email, password, firstName, lastName, phoneNumber } = payload
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    try {
      const authResponse = await firebase.auth().createUserWithEmailAndPassword(email, password)
      firestore.collection('users')
        .doc((authResponse.user as any).uid)
        .set({
          firstName,
          lastName,
          phoneNumber,
        })
      return dispatch({ type: ActionTypes.SIGN_UP_SUCCESS, payload })
    } catch (error) {
      return dispatch({ type: ActionTypes.SIGN_UP_FAIL, error })
    }
  }
}


type SignOutThunkAction = ThunkAction<Promise<SignOutActions>, any, ThunkExtraArgument, SignOutActions>

export const signOutActionCreator: ActionCreator<SignOutThunkAction> = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase()

    try {
      await firebase.auth().signOut()
      return dispatch({ type: ActionTypes.SIGN_OUT_SUCCESS })
    } catch (error) {
      return dispatch({ type: ActionTypes.SIGN_OUT_FAIL, error })
    }
  }
}
