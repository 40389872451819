import React from 'react';
import { useDispatch } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Patient from 'model/Patient';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DayJsUtils from '@date-io/dayjs';
import {
  KeyboardDateTimePicker,
  MaterialUiPickersDate,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import dayJs from 'dayjs';
import { setNextVisitActionCreator } from 'store/actionCreators/patientActionCreators';
import t from './locales/pl';

interface Props {
  patient: Patient
}

const PatientSummary: React.FC<Props> = ({ patient }) => {
  const { id, phoneNumber, nextVisitDate, lastName, firstName } = patient
  const [selectedDate, setSelectedDate] = React.useState<dayJs.Dayjs | null>(
    null,
  )

  React.useEffect(() => {
    setSelectedDate(nextVisitDate ? dayJs(nextVisitDate.toDate()) : null)
  }, [nextVisitDate])

  const dispatch = useDispatch()

  const onDateChange = React.useCallback(
    (date: MaterialUiPickersDate | null) => {
      setSelectedDate(date)
    },
    [],
  )

  const saveNextVisitDate = React.useCallback(() => {
    const nextVisitDate = selectedDate ? selectedDate.toDate() : null
    dispatch(setNextVisitActionCreator({ id, nextVisitDate }))
  }, [selectedDate, dispatch, id])

  return (
    <Grid container>
      <Grid item xs>
        <Box my={1}>
          <Typography variant="h5" color="primary">
            {firstName} {lastName}
          </Typography>
          <Typography variant="subtitle1">
            {t.phoneFormat(phoneNumber)}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box my={1} textAlign="right">
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <Grid container justify="space-around">
              <KeyboardDateTimePicker
                margin="normal"
                label={t.pickerLabel}
                format={t.pickerFormat}
                variant="inline"
                disablePast={true}
                ampm={false}
                hideTabs={true}
                minutesStep={5}
                value={selectedDate}
                onChange={onDateChange}
                onClose={saveNextVisitDate}
                KeyboardButtonProps={{ 'aria-label': t.pickerAriaLabel }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Box>
      </Grid>
    </Grid>
  )
}

export default firestoreConnect(['users'])(PatientSummary as any) as any
