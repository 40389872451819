import Patient from 'model/Patient';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  ActionTypes,
  SetNextVisitActions,
} from 'store/actions/patientActions';
import { ThunkExtraArgument } from 'store/types';

type SetNextVisitThunkAction = ThunkAction<Promise<SetNextVisitActions>, any, ThunkExtraArgument, SetNextVisitActions>

export const setNextVisitActionCreator: ActionCreator<SetNextVisitThunkAction> =
  (payload: Readonly<Pick<Patient, 'id' | 'nextVisitDate'>>) => {
    return async (dispatch, getState, { getFirestore }) => {
      const firestore = getFirestore()
      try {
        const { id, nextVisitDate } = payload;
        const patient = await firestore.collection('users')
          .doc(id)
          .update({ nextVisitDate })
        return dispatch({ type: ActionTypes.SET_NEXT_VISIT_SUCCESS, payload: patient })
      } catch (error) {
        return dispatch({ type: ActionTypes.SET_NEXT_VISIT_FAIL, error })
      }
    }
  }
