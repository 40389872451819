import Patient from 'model/Patient'
import { Reducer } from 'redux'
import { ActionTypes, PatientActions } from 'store/actions/patientActions'

export type State = Readonly<{
  patients: readonly Patient[]
}>

const initialState: State = {
  patients: [],
}

const reducer: Reducer<State, PatientActions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_NEXT_VISIT_SUCCESS:
      return state
    case ActionTypes.SET_NEXT_VISIT_FAIL:
      return state
    default:
      return state
  }
}

export default reducer
