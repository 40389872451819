import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import firebase from 'config/firebase'
import dayJs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import App from 'pages/App'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { applyMiddleware, compose, createStore } from 'redux'
import { createFirestoreInstance, getFirestore, reduxFirestore } from 'redux-firestore'
import thunk from 'redux-thunk'
import * as serviceWorker from 'serviceWorker'
import rootReducer from 'store/reducers/rootReducer'

dayJs.extend(relativeTime)

const store = createStore(rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({
      getFirebase: () => firebase,
      getFirestore,
    })),
    reduxFirestore(firebase),
  ))

const reactReduxFirebaseProviderProps = {
  firebase,
  config: {
    useFirestoreForProfile: true,
    userProfile: 'users',
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
}

const theme = createMuiTheme({
  palette: {
    primary: { main: '#3C508A' },
  },
})

const element = (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...reactReduxFirebaseProviderProps}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ReactReduxFirebaseProvider>
  </Provider>
)

ReactDOM.render(element, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Notes this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
