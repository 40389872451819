import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyA1JE0k9KFRSi2A9eUZzp8C3_M2414rBgY',
  authDomain: 'bio-klinika-patient-notes.firebaseapp.com',
  databaseURL: 'https://bio-klinika-patient-notes.firebaseio.com',
  projectId: 'bio-klinika-patient-notes',
  storageBucket: 'bio-klinika-patient-notes.appspot.com',
  messagingSenderId: '625887229089',
  appId: '1:625887229089:web:d6fe635809168ff9',
}

firebase.initializeApp(firebaseConfig)

export default firebase
