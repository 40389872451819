import Patient from 'model/Patient'
import { Action } from 'redux'
import { ErrorAction, Payload } from 'store/types'

export enum ActionTypes {
  SET_NEXT_VISIT_SUCCESS = '@patient/set_next_visit_success',
  SET_NEXT_VISIT_FAIL = '@patient/set_next_visit_fail',
}

export interface SetNextVisitSuccessAction extends Action<ActionTypes.SET_NEXT_VISIT_SUCCESS>, Payload<Readonly<Patient>> {}

export interface SetNextVisitErrorAction extends ErrorAction<ActionTypes.SET_NEXT_VISIT_FAIL> {}

export type SetNextVisitActions = SetNextVisitSuccessAction | SetNextVisitErrorAction


export type PatientActions = SetNextVisitActions
