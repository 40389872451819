import Note from 'model/Note'
import { Reducer } from 'redux'
import { ActionTypes, NoteActions } from 'store/actions/noteActions'

export type State = Readonly<{
  notes: readonly Note[]
}>

const initialState: State = {
  notes: [],
}

const reducer: Reducer<State, NoteActions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_SUCCESS:
      return state
    case ActionTypes.ADD_FAIL:
      return state
    case ActionTypes.REMOVE_SUCCESS:
      return state
    case ActionTypes.REMOVE_FAIL:
      return state
    default:
      return state
  }
}

export default reducer
