import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import dayJs from 'dayjs';
import NoteModel from 'model/Note';
import React from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import t from './locales/pl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleHeading: {
      fontSize: theme.typography.pxToRem(16),
      flexGrow: 1,
    },
    dateHeading: {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.secondary,
    },
  }),
)

interface Props {
  note: NoteModel
  isInitiallyExpanded?: boolean
  deleteNote: () => void
}

const Note: React.FC<Props> = ({
  note: { id, createdAt, title, content },
  isInitiallyExpanded = false,
  deleteNote,
}) => {
  const classes = useStyles()
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false)
  const [isExpanded, setIsExpanded] = React.useState<boolean>(
    isInitiallyExpanded,
  )
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setIsExpanded(isExpanded)
    },
    [],
  )

  const openDialog = React.useCallback(() => {
    setIsDialogOpen(true)
  }, [])

  const closeDialog = React.useCallback(() => {
    setIsDialogOpen(false)
  }, [])

  const onDialogAccept = React.useCallback(() => {
    setIsDisabled(true)
    setIsExpanded(false)
    deleteNote()
    closeDialog()
  }, [setIsDisabled, setIsExpanded, deleteNote, closeDialog])

  const date = dayJs(createdAt.toDate()).fromNow()

  return (
    <ExpansionPanel
      expanded={isExpanded}
      disabled={isDisabled}
      onChange={handleChange}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${id}-content`}
        id={`panel-${id}-header`}
      >
        <Typography className={classes.titleHeading}>{title}</Typography>
        <Typography className={classes.dateHeading}>{date}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography>{content}</Typography>
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <Button size="small" onClick={openDialog}>
          {t.deleteButtonLabel}
        </Button>
        <Button size="small" color="primary">
          {t.editButtonLabel}
        </Button>
        <ConfirmationDialog
          title={t.dialogTitle}
          isOpen={isDialogOpen}
          onClose={closeDialog}
          onCancel={closeDialog}
          onAccept={onDialogAccept}
        >
          {t.dialogContentFormat(title)}
        </ConfirmationDialog>
      </ExpansionPanelActions>
    </ExpansionPanel>
  )
}

export default Note
