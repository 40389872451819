import Note from 'model/Note'
import { Action } from 'redux'
import { ErrorAction, Payload } from 'store/types'

export enum ActionTypes {
  ADD_SUCCESS = '@note/add_success',
  ADD_FAIL = '@note/add_fail',
  REMOVE_SUCCESS = '@note/remove_success',
  REMOVE_FAIL = '@note/remove_fail',
}

export interface AddNoteSuccessAction extends Action<ActionTypes.ADD_SUCCESS>, Payload<Readonly<Note>> {}

export interface AddNoteErrorAction extends ErrorAction<ActionTypes.ADD_FAIL> {}

export type AddNoteActions = AddNoteSuccessAction | AddNoteErrorAction


export interface RemoveNoteSuccessAction extends Action<ActionTypes.REMOVE_SUCCESS>, Payload<Readonly<Pick<Note, 'id'>>> {}

export interface RemoveNoteErrorAction extends ErrorAction<ActionTypes.REMOVE_FAIL> {}

export type RemoveNoteActions = RemoveNoteSuccessAction | RemoveNoteErrorAction


export type NoteActions = AddNoteActions | RemoveNoteActions
