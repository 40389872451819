import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import PatientModel from 'model/Patient';
import React from 'react';
import { containsSucceedingSubstring } from './utils';
import t from './locales/pl';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    input: {
      flex: 1,
    },
  }),
)

interface Props {
  patients: readonly PatientModel[]
  selectedPatientId: string
  setSelectedPatientId: (id: string) => void
}

const PatientList: React.FC<Props> = ({ patients, selectedPatientId, setSelectedPatientId }) => {
  const classes = useStyles()

  const [searchText, setSearchText] = React.useState('')

  const filteredPatients = patients.filter((patient) => !searchText ||
      containsSucceedingSubstring(searchText, patient.firstName) ||
      containsSucceedingSubstring(searchText, patient.lastName) ||
      containsSucceedingSubstring(searchText, patient.phoneNumber))

  return (
    <div className={classes.root}>
      <Box my={1}>
        <Typography variant="h5" color="primary">
          {t.header}
        </Typography>
      </Box>
      <Paper>
        <List component="nav" aria-label={t.listAriaLabel}>
          <ListItem>
            <InputBase
              className={classes.input}
              placeholder={t.searchBoxPlaceholder}
              inputProps={{ 'aria-label': t.searchBoxAriaLabel }}
              onChange={event => setSearchText(event.target.value.trim())}
            />
            <IconButton aria-label={t.searchIconAriaLabel}>
              <SearchIcon />
            </IconButton>
          </ListItem>
          <Divider />
          {filteredPatients.map(({ id, firstName, lastName, phoneNumber }) => (
            <ListItem
              key={id}
              button
              selected={selectedPatientId === id}
              onClick={() => setSelectedPatientId(id)}
              dense
            >
              <ListItemText primary={`${firstName} ${lastName}`} secondary={t.phoneFormat(phoneNumber)} />
            </ListItem>
          ))}
          {!filteredPatients.length && (
            <ListItem>
              <ListItemText>No patients found</ListItemText>
            </ListItem>
          )}
        </List>
      </Paper>
    </div>
  )
}

export default PatientList
