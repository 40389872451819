import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutActionCreator } from 'store/actionCreators/authActionCreators';
import { AppState } from 'store/types';
import t from './locales/pl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  }),
)

interface Props {
  title?: string
}

const AuthorizedLayout: React.FC<Props> = ({
  children,
  title = t.defaultTitle,
}) => {
  const { firstName = '', lastName = '' } = useSelector<AppState, any>(
    (state) => (state.firebase as any).profile,
  )
  const dispatch = useDispatch()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const initials = `${firstName.substring(0, 1)}${lastName.substring(
    0,
    1,
  )}`.toUpperCase()
  const isMenuOpen = Boolean(anchorEl)

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleSignOut = (): void => {
    dispatch(signOutActionCreator())
    handleClose()
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <div>
            <IconButton
              aria-label={t.profileIconAriaLabel}
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {initials ? initials : <AccountCircle />}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={isMenuOpen}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>{t.menuItemProfile}</MenuItem>
              <MenuItem onClick={handleSignOut}>{t.menuItemSignOut}</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Box marginY={3}>
        <Container>{children}</Container>
      </Box>
    </div>
  )
}

export default AuthorizedLayout
