import { Reducer } from 'redux'
import { ActionTypes, AuthActions } from 'store/actions/authActions'

export type State = Readonly<{
  error?: Error
}>

const initialState: State = {}

const reducer: Reducer<State, AuthActions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SIGN_IN_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case ActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        error: undefined,
      }

    case ActionTypes.SIGN_UP_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case ActionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        error: undefined,
      }
    default:
      return state
  }
}

export default reducer
