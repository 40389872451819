import SignIn from 'components/SignIn'
import SignUp from 'components/SignUp'
import AuthorizedLayout from 'layouts/AuthorizedLayout'
import UnauthorizedLayout from 'layouts/UnauthorizedLayout'
import Notes from 'pages/Notes/Notes'
import NotFound from 'pages/NotFound'
import Patients from 'pages/Patients'
import Profile from 'pages/Profile'
import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { AppState } from 'store/types'

const Unauthorized: React.FC = () => (
  <UnauthorizedLayout>
    <Switch>
      <Route path="/sign-in" component={SignIn} />
      <Route path="/sign-up" component={SignUp} />
      <Redirect to="/sign-in" />
    </Switch>
  </UnauthorizedLayout>
)

const Patient: React.FC = () => (
  <AuthorizedLayout>
    <Switch>
      <Redirect from="/sign-in" exact to="/" />
      <Redirect from="/sign-up" exact to="/" />
      <Route exact path="/" component={Notes} />
      <Route path="/profile" component={Profile} />
      <Route component={NotFound} />
    </Switch>
  </AuthorizedLayout>
)

const Dashboard: React.FC = () => (
  <AuthorizedLayout>
    <Switch>
      <Redirect from="/sign-in" exact to="/" />
      <Redirect from="/sign-up" exact to="/" />
      <Redirect from="/" exact to="/patients" />
      <Route path="/patients/" exact component={Patients} />
      <Route path="/patients/:id" component={Patients} />
      <Route component={NotFound} />
    </Switch>
  </AuthorizedLayout>
)

const Authorized: React.FC = () => {
  const isEmployee = useSelector<AppState, boolean>((state) => (state.firebase as any).profile.isEmployee)
  return (
    isEmployee ? <Dashboard /> : <Patient />
  )
}

const LayoutSwitcher: React.FC = () => {
  // TODO: change type of state.firebase
  const isSignedIn = useSelector<AppState, boolean>((state) => !(state.firebase as any).auth.isEmpty)
  return (
    !isSignedIn ? <Unauthorized /> : <Authorized />
  )
}

const App: React.FC = () => {
  const isLoaded = useSelector<AppState, boolean>((state) => (state.firebase as any).auth.isLoaded)
  if (!isLoaded) {
    return null
  }
  return (
    <Router>
      <LayoutSwitcher />
    </Router>
  )
}

export default App
