import { Action } from 'redux'
import { ErrorAction, Payload } from 'store/types'

export enum ActionTypes {
  SIGN_UP_SUCCESS = '@auth/sign_up_success',
  SIGN_UP_FAIL = '@auth/sign_up_fail',
  SIGN_IN_SUCCESS = '@auth/sign_in_success',
  SIGN_IN_FAIL = '@auth/sign_in_fail',
  SIGN_OUT_SUCCESS = '@auth/sign_out_success',
  SIGN_OUT_FAIL = '@auth/sign_out_fail',
}

export interface SignUpData {
  firstName: string
  lastName: string
  email: string
  password: string
  phoneNumber: string
}

export interface SignUpSuccessAction extends Action<ActionTypes.SIGN_UP_SUCCESS>, Payload<Readonly<SignUpData>> {}

export interface SignUpErrorAction extends ErrorAction<ActionTypes.SIGN_UP_FAIL> {}

export type SignUpActions = SignUpSuccessAction | SignUpErrorAction


export interface SignInData {
  email: string
  password: string
}

export interface SignInSuccessAction extends Action<ActionTypes.SIGN_IN_SUCCESS>, Payload<Readonly<SignInData>> {}

export interface SignInErrorAction extends ErrorAction<ActionTypes.SIGN_IN_FAIL> {}

export type SignInActions = SignInSuccessAction | SignInErrorAction


export interface SignOutSuccessAction extends Action<ActionTypes.SIGN_OUT_SUCCESS> {}

export interface SignOutErrorAction extends ErrorAction<ActionTypes.SIGN_OUT_FAIL> {}

export type SignOutActions = SignOutSuccessAction | SignOutErrorAction


export type AuthActions = SignUpActions | SignInActions | SignOutActions
