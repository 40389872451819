export const containsSucceedingSubstring = (
  string: string,
  substring: string,
): boolean => {
  let index = -1
  const substringLowerCase = substring.toLowerCase()
  return string
    .toLowerCase()
    .split('')
    .reduce((allCharsFulfillCondition: boolean, char: string) => {
      index = substringLowerCase.indexOf(char, index + 1)
      return allCharsFulfillCondition && index !== -1
    }, true)
}
