export default {
  header: 'Zarejestruj się',
  firstNameLabel: 'Imię',
  lastNameLabel: 'Nazwisko',
  phoneNumberLabel: 'Numer Telefonu',
  emailLabel: 'Adres Email',
  passwordLabel: 'Hasło',
  acceptToc: 'Akceptuję warunki…',
  submitButtonLabel: 'Zarejestruj się',
  signInLink: 'Masz już konto? Zaloguj się!',
}
