import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import t from './locales/pl';

interface Props {
  title: string
  isOpen: boolean
  cancelLabel?: string
  acceptLabel?: string
  onClose?: () => void
  onCancel?: () => void
  onAccept?: () => void
}

const ConfirmationDialog: React.FC<Props> = ({
  children,
  title,
  isOpen,
  cancelLabel = t.defaultCancelLabel,
  acceptLabel = t.defaultAcceptLabel,
  onClose = () => {},
  onCancel = () => {},
  onAccept = () => {},
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          {cancelLabel}
        </Button>
        <Button onClick={onAccept} color="primary" autoFocus>
          {acceptLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
