import { firebaseReducer } from 'react-redux-firebase'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import authReducer from './authReducer'
import noteReducer from './noteReducer'
import patientReducer from './patientReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  note: noteReducer,
  patient: patientReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
})

export default rootReducer
