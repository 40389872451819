import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Field, Form, Formik, FormikActions } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signUpActionCreator } from 'store/actionCreators/authActionCreators';
import { AppState } from 'store/types';
import t from './locales/pl';

export interface Values {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  password: string
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))


const SignUp: React.FC = () => {
  const error = useSelector<AppState, Error | undefined>(state => state.auth.error)
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleSubmit = (values: Values, actions: FormikActions<Values>): void => {
    console.log(' # handleSubmit @ 52 (SignUp.tsx)\n', { values })
    dispatch(signUpActionCreator(values))
    actions.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        password: '',
      }}
      onSubmit={handleSubmit}
      render={({ isSubmitting }) => (
        <Form className={classes.form}>
          <div className={classes.header}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t.header}
            </Typography>
          </div>
          <Field
            component={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t.firstNameLabel}
            name="firstName"
            autoFocus
          />
          <Field
            component={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t.lastNameLabel}
            name="lastName"
          />
          <Field
            component={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t.emailLabel}
            name="email"
            autoComplete="email"
          />
          <Field
            component={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t.passwordLabel}
            name="password"
            type="password"
          />
          <Field
            component={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t.phoneNumberLabel}
            name="phoneNumber"
          />
          {error && <Typography color="error">{error.message}</Typography>}
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label={t.acceptToc}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting}
          >
            {t.submitButtonLabel}
          </Button>
          <Grid container>
            <Grid item xs />
            <Grid item>
              <Link href="/sign-in" variant="body2">
                {t.signInLink}
              </Link>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  )
}

export default SignUp
