import Note from 'model/Note'
import { ActionCreator } from 'redux'
import { ThunkAction } from 'redux-thunk'
import {
  ActionTypes,
  AddNoteActions,
  RemoveNoteActions,
} from 'store/actions/noteActions';
import { ThunkExtraArgument } from 'store/types'

type AddNoteThunkAction = ThunkAction<Promise<AddNoteActions>, any, ThunkExtraArgument, AddNoteActions>

export const addNoteActionCreator: ActionCreator<AddNoteThunkAction> =
  (payload: Readonly<Pick<Note, 'title' | 'content' | 'userId'>>) => {
    return async (dispatch, getState, { getFirestore }) => {
      const firestore = getFirestore()
      try {
        const note = await firestore.collection('notes')
          .add({
            ...payload,
            createdAt: new Date(),
          })
        return dispatch({ type: ActionTypes.ADD_SUCCESS, payload: note })
      } catch (error) {
        return dispatch({ type: ActionTypes.ADD_FAIL, error })
      }
    }
  }


type RemoveNoteThunkAction = ThunkAction<Promise<RemoveNoteActions>, any, ThunkExtraArgument, RemoveNoteActions>

export const removeNoteActionCreator: ActionCreator<RemoveNoteThunkAction> =
  (payload: Readonly<Pick<Note, 'id'>>) => {
    return async (dispatch, getState, { getFirestore }) => {
      const firestore = getFirestore()
      try {
        await firestore.collection('notes')
          .doc(payload.id)
          .delete()
        return dispatch({ type: ActionTypes.REMOVE_SUCCESS, payload })
      } catch (error) {
        return dispatch({ type: ActionTypes.REMOVE_FAIL, error })
      }
    }
  }
