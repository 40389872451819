import AddNote from 'components/AddNote'
import NotesComponent from 'components/Notes'
import Note from 'model/Note'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { RouteComponentProps } from 'react-router-dom'
import { addNoteActionCreator } from 'store/actionCreators/noteActionCreators'
import { AppState } from 'store/types'
import PatientSummary from '../../components/PatientSummary/PatientSummary'
import Patient from '../../model/Patient'

interface Props {
  id: string
}

const SelectedPatient: React.FC<RouteComponentProps<Props>> = ({ match }) => {
  const currentUserId = useSelector<AppState, string>(
    (state) => (state.firebase as any).auth.uid,
  )

  const patients = useSelector<AppState, readonly Patient[]>(
    (state) => state.firestore.ordered.users,
  )
  const notes = useSelector<AppState, readonly Note[]>(
    (state) => state.firestore.ordered.notes,
  )
  const dispatch = useDispatch()

  const { params } = match
  const { id = currentUserId } = params
  const patient = (patients || []).find((patient: Patient) => patient.id === id)
  const patientNotes = (notes || []).filter(({ userId }) => userId === id)

  const handleAddNote = (
    note: Readonly<Pick<Note, 'title' | 'content'>>,
  ): void => {
    const noteWithUserId = {
      ...note,
      userId: id,
    }
    dispatch(addNoteActionCreator(noteWithUserId))
  }

  return (
    <div>
      {patient && <PatientSummary patient={patient} />}
      <AddNote addNote={handleAddNote} />
      {notes && <NotesComponent notes={patientNotes} />}
    </div>
  )
}

export default firestoreConnect(['users', 'notes'])(
  SelectedPatient as any,
) as any
