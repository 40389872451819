import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import backgroundImage from './assets/amplitude-magazin.jpg'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
  },
}))

// eslint-disable-next-line react/display-name
const SquarePaper = React.forwardRef((props, ref) => (
  <Paper innerRef={ref} elevation={6} square {...props} />
))

const UnauthorizedLayout: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={SquarePaper}>
        <div className={classes.paper}>
          {children}
        </div>
      </Grid>
    </Grid>
  )
}

export default UnauthorizedLayout
